import { KEYS } from '@server/utils/parseLoop/const';
import type { Item } from '@server/utils/parseLoop/types';
import type { Attributes, StructuredItem, Variant } from 'types/product';

export default function parseVariant({ attributes, id }: Item): Variant {
    const variant: Partial<Variant> = {
        type: 'Variant',
    };

    for (let i = 0; i < attributes?.length; i += 1) {
        const { name, values } = attributes[i];
        if (name === KEYS.VARIANT_ID_KEY) variant.id = values[0] as string;
        else if (name === KEYS.NAME_KEY) variant.title = values[0] as string;
        else if (name === KEYS.IMAGE_KEY) {
            variant.images = values.map((value, index, arr) => ({
                src: value as string,
                type: 'image',
                alt: `image ${index + 1} of ${arr.length}`,
                id: `${id} #${index}`,
            }));
            variant.image = variant.images.at(0);
        } else if (name === KEYS.GROUP_KEY) {
            variant.groupId = values[0] as string;
        } else if (name === KEYS.PRICE_KEY) {
            variant.price = Math.min(...(values as number[])) as number;
        } else if (name === KEYS.ORIGINAL_PRICE_KEY) {
            variant.campaignPrice = true;
            variant.originalPrice = Math.min(...(values as number[])) as number;
        } else if (name === KEYS.PRICE_TYPE_KEY) {
            variant.priceType = values.at(0) as string;
        } else if (name === KEYS.RRP_WHEN_CAMPAIGN_KEY) {
            variant.rrpWhenCampaign = values.at(0) as number;
        } else if (name === KEYS.VAT_RATE_KEY) {
            variant.vatRate = Math.min(...(values as number[])) as number;
        } else if (name === KEYS.VARIANT_ATTRIBUTES_KEY) {
            const parsed = JSON.parse(values[0] as string) as Record<
                string,
                Attributes
            >;
            variant.VariantDefiningAttributes = values[0] as string;
            Object.entries(parsed).forEach(([key, val]) => {
                variant[key] = val.Value;
            });
        } else if (name === KEYS.STRUCTURE_ITEMS_KEY) {
            const items = values.map((value) => {
                if (typeof value !== 'string') return null;
                try {
                    const item = JSON.parse(value);
                    return {
                        partNo: item.PartNo,
                        quantity: parseInt(item.Quantity, 10),
                        order: parseInt(item.SortOrder, 10),
                    };
                } catch (error: SyntaxError | unknown) {
                    if (error instanceof SyntaxError) {
                        console.warn(
                            'parseVariant error - Failed to parse value',
                            error,
                        );
                    }
                    return null;
                }
            });
            variant.items = items.filter(
                (item): item is StructuredItem => item !== null,
            );
        } else if (name === KEYS.DELIVERY_MIN_KEY)
            variant.deliveryMin = Number(values[0]); // TEMP
        else if (name === KEYS.DELIVERY_MAX_KEY)
            variant.deliveryMax = Number(values[0]); // TEMP
        else if (name === KEYS.NEXT_DELIVERY_DATE)
            variant.nextDeliveryDate = new Date(values[0] as string);
        else if (name === KEYS.DELIVERY_UNIT_KEY)
            variant.deliveryUnit = values[0] as string;
        else if (name === KEYS.DELIVERY_TIME_DAYS_KEY)
            variant.deliveryTimeDays = values[0] as number;
        else if (name === KEYS.STOCK_QUANTITY_KEY)
            variant.stockQuantity = values[0] as number;
        else if (name === KEYS.ARTICLE_STATUS_KEY)
            variant.articleStatus = values[0] as string;
        else if (name === KEYS.RELATED_PRODUCTS_KEY) {
            variant.relatedProducts = values as string[];
        } else if (name === KEYS.VARIANT_BRAND_KEY) {
            variant.brand = values[0] as string;
        } else if (name === KEYS.FLAGS_KEY) {
            variant.flags = values as string[];
        } else if (name === KEYS.LIGHTSOURCES_QUANTITY_KEY)
            variant.lightsourceQuantity = Number(values[0]);
        else if (name === KEYS.STOCK_STATUS_KEY) {
            variant.stockStatus = values[0] as string;
            variant.stock = values[0] === 'StockItem';
        } else variant[name] = values.length > 1 ? values : values[0];
    }
    return variant as Variant;
}
