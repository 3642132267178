import { isValidLocale } from '@/config/market-configurations';

export const getDetailsFromPathname = (
    pathname: string,
): { locale: string; slug: string[] | undefined } => {
    const slugs = pathname.split('/').filter(Boolean);
    const [locale, ...rest] = slugs;

    if (!locale) {
        return {
            locale: '',
            slug: [],
        };
    }

    if (!isValidLocale(locale)) {
        return {
            locale: '',
            slug: [locale, ...rest],
        };
    }

    return {
        locale,
        slug: rest,
    };
};
