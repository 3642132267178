import type { Sort } from 'types';

import { DEFAULT_CATEGORY_ATTRIBUTE } from '@/utils/const';

export const PRODUCTS_ENDPOINT = 'getEntities';

export const DEFAULT_SORT: Sort = 'relevance';

export const KEYS = {
    HEIGHT_KEY: 'height_cm',
    WIDTH_KEY: 'width_cm',
    DEPTH_KEY: 'depth_cm',
    WEIGHT_KEY: 'weight_cm',
    COLOR_KEY: 'norce_color',
    ID_KEY: 'Id',
    VARIANT_ID_KEY: 'Code',
    TYPE_KEY: 'type',
    NAME_KEY: 'Name',
    IMAGE_KEY: 'Images',
    VIDEO_KEY: 'Videos',
    DESCRIPTION_KEY: 'Description',
    GROUP_KEY: 'GroupId',
    CATEGORY_KEY: 'CategoryPath.Code',
    DESIGNER_KEY: 'Designer',
    DESIGNERS_KEY: 'Designers.Value',
    BRAND_KEY: 'Brand',
    VARIANT_BRAND_KEY: 'norce_brand',
    FLAGS_KEY: 'Flags',
    ARTICLE_STATUS_KEY: 'ArticleStatusFlag',
    STOCK_KEY: 'InStock',
    CAMPAIGN_KEY: 'Campaign',
    PRICE_KEY: 'Price',
    ORIGINAL_PRICE_KEY: 'OriginalPrice',
    PRESENTATION_PRICE_KEY: 'PresentationPrice',
    PRESENTATION_ORIGINAL_KEY: 'PresentationOriginal',
    PRESENTATION_PRICE_TYPE_KEY: 'PresentationPriceType',
    PRESENTATION_RRP_WHEN_CAMPAIGN_KEY: 'PresentationRrpWhenCampaign',
    RRP_WHEN_CAMPAIGN_KEY: 'RrpWhenCampaign',
    PRICE_TYPE_KEY: 'PriceType',
    VAT_RATE_KEY: 'VatRate',
    FROM_PRICE_KEY: 'FromPrice',
    // CAMPAIGN_PRICE_KEY: 'CampaignPrice',
    // CAMPAIGN_FROM_PRICE_KEY: 'CampaignFromPrice',
    NUMBER_OF_VARIANTS_KEY: 'NrOfVariants',
    VARIANT_ATTRIBUTES_KEY: 'VariantDefiningAttributes',
    VARIANT_ATTRIBUTES_NAMES_KEY: 'VariantDefiningAttributesNames',
    RECOMMENDED_LIGHTSOURCES_KEY: 'Rekommenderad ljuskälla',
    RELATED_LIGHTSOURCES_KEY: 'Ljuskälla',
    RELATED_PRODUCTS_KEY: 'Tillbehör',
    LIGHTSOURCES_QUANTITY_KEY: 'norce_light_source_quantity',
    DELIVERY_MIN_KEY: 'MinDeliveryTime',
    DELIVERY_MAX_KEY: 'MaxDeliveryTime',
    DELIVERY_UNIT_KEY: 'DeliveryUnit',
    NEXT_DELIVERY_DATE: 'NextDeliveryDate',
    STOCK_STATUS_KEY: 'StockStatus',
    STOCK_QUANTITY_KEY: 'StockQuantity',
    STRUCTURE_ITEMS_KEY: 'StructureItems',
    BRAND_KEY_CODE: 'Brands.Code',
    DESIGNER_KEY_CODE: 'Designers.Code',
    CATEGORY_KEY_CODE: DEFAULT_CATEGORY_ATTRIBUTE,
    DELIVERY_TIME_DAYS_KEY: 'delivery_time_days',
};
